import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

export function Success(){
    const data = useSelector((state) =>state.tests.data)
    console.log(data)


    const testname_ = data[data.length-1].testname
    console.log(testname_)
    return(
        <section id="centerContent" className="bgBlock">
            <div className="centerWrapper">
                <div className="centerCard">
                    <img src="assets/img/check_circle.svg" alt="" />
                    <h1 className="successDesc"> <strong>“{testname_}”</strong> test kurulumunuz<br />tamamlanmıştır.</h1>
                    <div className="successLink">
                        Tüm test listesini görmek için <Link to="/" >tıklayın</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}