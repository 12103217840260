import Header from "../components/temp/header";
import {Step2} from "../components/page/step2";
import {useDispatch, useSelector} from "react-redux";
import {Step1} from "../components/page/step1";
import {error_update} from "../redux/other/slice";

export default  function Step_2(){
    const disp = useDispatch()
    const data = useSelector ((state) => state.tests.step1)

    if(data[0] === '' || data[1] === ''){

        disp(error_update(true))
    }

    if (data[0] === '' || data[1] === ''){
        console.log(111)
        }
    return (
        <>
            <Header/>
            {data[0] !== '' && data[1] !== '' ? <Step2/> : <Step1/>}
        </>
    )
}