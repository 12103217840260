import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {lastPage_Update} from "../../redux/other/slice";

export default  function Header(){
    const back_page = useSelector((state)=>state.tests.last_page)
    const disp = useDispatch()
    const page=()=>{
        disp(lastPage_Update(window.location.pathname))
    }
    return(
        <section id="header">
            <div className="themeRow">
                <div className="themeContainer">
                    <div className="headerWrapper">
                        <div className="headerLeft">
                            <div className="backButton"> <Link to={back_page}><img src="assets/img/back.svg" alt="" /></Link> </div>
                            <div className="logo"> <Link to="/" onClick={page}> <img src="assets/img/tikqa.png" alt="" /></Link></div>
                        </div>
                        <div className="headerRight">
                            <a href="#"> <img src="assets/img/menu.svg" alt="" /> </a>
                            <a href="#"> <img src="assets/img/user.svg" alt="" /> </a>
                            <a href="#"> <img src="assets/img/settings.svg" alt="" /> </a>
                        </div>
                    </div>
                    <div className="navBar">
                        <ul>
                            <li> <a href="#" className="activeLink"> Testler </a> </li>
                            <li> <a href="#"> Tüm Aktiviteler </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}