import {useDispatch, useSelector} from "react-redux";
import {lastPage_Update, step1_update, succes1_update,error_update} from "../../redux/other/slice";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {nanoid} from "@reduxjs/toolkit";

let etiketler = null /// etiketleri maplemek için
let error_=null
export function Step1(){


    const disp = useDispatch()

    //// step1 için data varsa eğer sonraki sayfadan geri geldiyse diye state
    const data = useSelector ((state) => state.tests.step1)
    const [dize,setDize] = useState(data[2]!= [] ? data[2] :[] )

    //// textboxlar///////
    const [name,setName]=useState(data[0] != '' ? data[0] : '');
    const [kabul,setKabul]=useState(data[1] != '' ? data[1] : '');
    /////////////////////


    ////enter sonrası etiket kutusunu boş yapmak için ////
    const [text,setText] = useState("")
    //////


    const error_message = useSelector((state) => state.tests.error_)
    console.log('error message : ' + error_message)

    const etiket_sil = (e,s)=>{
        setDize(dize.filter((item)=> item != e))
    }
    console.log(dize)

    const page=()=>{
        disp(lastPage_Update('/step-1'))
        disp(step1_update([name,kabul, dize]))
        disp(succes1_update(true))
    }
    const enter = (e) =>{
        if (e.key=='Enter'){
            setDize(prevArray => [...prevArray, e.target.value])
            setText("")
        }
    }



    if (dize!=[]){
        etiketler = dize.map((e,i)=>
            <a value={i} etk_name={e} onClick={(s) =>etiket_sil(e,s)}> <img src="assets/img/delete.svg" alt="" /> {e} </a>
        )
    }




    return(

        <section id="centerContent">
            <div className="centerWrapper">
                <div className="stepWrapper">
                    <ul>
                        <li> <Link to="/step-1" onClick={page} className="activeStep"> <span>1</span> Test bilgileri </Link> </li>
                        <li> <Link to="/step-2" onClick={page}> <span>2</span> Test akışı oluştur </Link> </li>
                        <li> <Link to="/step-3" onClick={page}> <span>3</span> Çalışma takvimi </Link> </li>
                    </ul>
                </div>
                <div className="centerCard formV">
                    <div className="cardTitle"> <span>1</span> Test bilgileri</div>
                    <span style={{color:"red"}}>{error_message ===true ? 'Tüm alanları eksiksiz doldurunuz ! ' : ''}</span>
                    <div className="formBox">
                        <form action>
                            <div className="formInput">
                                <label htmlFor="testname">Test Adı *</label>
                                <input id="testname" type="text" placeholder="Input" value={name} onChange={(e)=>setName(e.target.value)}/>
                            </div>
                            <div className="formInput">
                                <label htmlFor="criteria">Test kabul kriterleri (İsteğe bağlı)</label>
                                <textarea name="criteria" id="criteria" defaultValue={""} value={kabul} onChange={(e)=>setKabul(e.target.value)} />
                            </div>
                            <div className="formInput mb10">
                                <label htmlFor="testname">Etiket (İsteğe bağlı)</label>
                                <input id="testname" type="text" placeholder="Input" value={text} onKeyPress={(e)=>enter(e)}  onChange={(e)=>setText(e.target.value)}/>
                                <span>Etiketlerinizi yazdıktan sonra enter’a basın</span>
                            </div>
                            <div className="tagsArea">
                                {etiketler}
                            </div>
                        </form>
                    </div>
                    <div className="formButton">
                        <Link to="/step-2" onClick={page}><img src="assets/img/right.svg" alt="" /> Test akışı oluştur</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}