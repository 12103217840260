import Header from "../components/temp/header";
import {Step1} from "../components/page/step1";

export default  function Step_1(){

    return (
        <>
            <Header/>
            <Step1/>
        </>
    )
}