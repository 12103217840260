import Header from "../components/temp/header";
import CreateTest from "../components/page/createTest";

export default  function Create_Test(){
    return (
        <>
            <Header/>
            <CreateTest/>
        </>
    )
}