import {useDispatch, useSelector} from "react-redux";
import {addTest, error_update, lastPage_Update, secilitest_update, step2_update} from "../../redux/other/slice";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {logDOM} from "@testing-library/react";
import axios from "axios";

let testname = null
let true_ = []
let platform_ = []

let ay_ = null
export function Step3(){
    /// true_ , testskl , testtkr , date , platform , os , browser :::: Gidecek veriler
    var now  = new Date().toLocaleDateString()


    const suan = new Date(0)

    const [page_state,setPage] = useState(0)
    const disp = useDispatch()
    const testdata = useSelector((state) =>state.tests.secilitest)
    const data = useSelector ((state) => state.tests.step1)
    const tests_ =useSelector((state)=>state.tests.data)
    const [date,setDate] = useState("2022-11-16")
    const [testskl,setTestskl] = useState("Her saat başı")
    const [tekrar,setTekrar] =useState(1)
    const [testEkran,setTestEkran] = useState(false)
    const [testVideo,setTestVideo] = useState(false)
    const yil =   date.slice( 0 , 4 );
    const ay =   date.slice( 5 , 7 );
    const gun  =  date.slice( 8 , 10 );

    console.log(gun)

    if (ay ==='1'){
       ay_='Ocak'
    }else if(ay ==='2'){
        ay_='Şubat'
    }else if(ay ==='3'){
        ay_='Mart'
    }else if(ay ==='4'){
        ay_='Nisan'
    }else if(ay ==='5'){
        ay_='Mayıs'
    }else if(ay ==='6'){
        ay_='Haziran'
    }else if(ay ==='7'){
        ay_='Temmuz'
    }else if(ay ==='8'){
        ay_='Ağustos'
    }else if(ay ==='9'){
        ay_='Eylül'
    }else if(ay ==='10'){
        ay_='Ekim'
    }else if(ay === '11'){
        ay_='Kasım'
    }else if(ay === '12'){
        ay_='Aralık'
    }

    const tarih = gun + " " + ay_ + " " + yil
    console.log(tarih)
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [platformState_,setPlatformState_] = useState([])

    useEffect(
        () => {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://tikqa.herokuapp.com/tikqa/api/v1/operator/platform',
                headers: {
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJkaWxlayIsImV4cCI6MTY4NDQ5OTQyNywiaWF0IjoxNjg0NDg4NjI3fQ.giMwYRUw2qwTckJXXQryKzoaGixa7LWudzeNRGTyR2k',
                    'Cookie': 'JSESSIONID=1DDD3570993AFC79B8E1707D436DACC6'
                }
            };

            axios.request(config)
                .then((response) => {
                    setPlatformState_(response.data.data.map((platform_eleman)=>{
                        return {id:platform_eleman.id,name:platform_eleman.name,status:false}
                    }))
                })
                .catch((error) => {
                    let data = {
                        "httpStatus": "OK",
                        "successful": true,
                        "errorCode": null,
                        "errorMessage": null,
                        "data": [
                            {
                                "createdAt": "2023-05-16T07:04:28.113609",
                                "createdBy": "denizkaragoz0",
                                "updatedAt": "2023-05-16T07:04:28.113614",
                                "updatedBy": "denizkaragoz0",
                                "id": 1,
                                "name": "Tablet"
                            },
                            {
                                "createdAt": "2023-05-16T07:04:28.113631",
                                "createdBy": "denizkaragoz0",
                                "updatedAt": "2023-05-16T07:04:28.113632",
                                "updatedBy": "denizkaragoz0",
                                "id": 2,
                                "name": "Web"
                            },
                            {
                                "createdAt": "2023-05-16T07:04:28.113636",
                                "createdBy": "denizkaragoz0",
                                "updatedAt": "2023-05-16T07:04:28.113637",
                                "updatedBy": "denizkaragoz0",
                                "id": 3,
                                "name": "Mobil Uygulama"
                            },
                            {
                                "createdAt": "2023-05-16T07:04:28.11364",
                                "createdBy": "denizkaragoz0",
                                "updatedAt": "2023-05-16T07:04:28.113641",
                                "updatedBy": "denizkaragoz0",
                                "id": 4,
                                "name": "Mobil Görünüm"
                            }
                        ]
                    }

                    setPlatformState_(data.data.map((platform_eleman)=>{
                        return {id:platform_eleman.id,name:platform_eleman.name,status:false}
                    }))
                });


            //{id: 1, name: 'Desktop',status:false},
            //         {id: 2, name: 'Tablet',status: false},
            //         {id: 3, name: 'Mobil',status: false},
            //         {id: 4, name: 'All',status: false}
        }
    ,[])





    const [platform , setPlatform] = useState(platformState_)
    platform_ = platform.filter((platform_eleman)=> platform_eleman.status != false)
    const update_Platform = (i) => {

            if (i === "all"){
                setPlatformState_(
                    platformState_.map(veri => {
                        return {...veri, status: true }
                    })
                )
            }else{

                setPlatformState_(
                    platformState_.map(veri => {
                        if (veri.id == i) {
                            return {...veri, status: !veri.status};
                        }else{
                            return veri;
                        }
                    })
                )
            }


        // if (i===4){
        //    ////////
        //     if (platform[3].status){
        //         setPlatform(platformState_)
        //     }
        //     else if (!platform[3].status){
        //         setPlatform(
        //             platformState_.map(veri => {
        //                 return {...veri, status: true};
        //             })
        //         )
        //     }
        //
        //     ////////
        // }else{
        //   const platform_newstate = platform.map(veri => {
        //
        //         if (veri.id === i) {
        //             if (veri.status){
        //                 return {...veri, status: false};
        //             }else if(!veri.status){
        //                 return {...veri, status: true};
        //             }
        //         }
        //
        //         return veri;
        //     });
        //     setPlatform(platform_newstate);
        // }




    };
    console.log(platform)
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     const OS_State_ = [
//         {id: 1, name: 'Mac OS',status:false},
//         {id: 2, name: 'Linux',status: false},
//         {id: 3, name: 'Windows 10',status: false},
//         {id: 4, name: 'Windows 8',status: false},
//         {id: 5, name: 'Windows 7',status: false},
//         {id: 6, name: 'All',status: false}
//     ];

    const [OS_State_,setOS_State_] = useState([])

    useEffect(
        () => {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://tikqa.herokuapp.com/tikqa/api/v1/operator/operating-system/1',
                headers: {
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJkaWxlayIsImV4cCI6MTY4NDQ5OTQyNywiaWF0IjoxNjg0NDg4NjI3fQ.giMwYRUw2qwTckJXXQryKzoaGixa7LWudzeNRGTyR2k',
                    'Cookie': 'JSESSIONID=1DDD3570993AFC79B8E1707D436DACC6'
                }
            };

            axios.request(config)
                .then((response) => {
                    setOS_State_(response.data.data.map((platform_eleman)=>{
                        return {id:platform_eleman.id,name:platform_eleman.name,status:false}
                    }))
                })
                .catch((error) => {
                    let data = {
                        "httpStatus": "OK",
                        "successful": true,
                        "errorCode": null,
                        "errorMessage": null,
                        "data": [
                            {
                                "createdAt": "2023-05-16T07:04:28.125126",
                                "createdBy": "denizkaragoz0",
                                "updatedAt": "2023-05-16T07:04:28.12513",
                                "updatedBy": "denizkaragoz0",
                                "id": 1,
                                "name": "Windows"
                            },
                            {
                                "createdAt": "2023-05-16T07:04:28.125136",
                                "createdBy": "denizkaragoz0",
                                "updatedAt": "2023-05-16T07:04:28.125138",
                                "updatedBy": "denizkaragoz0",
                                "id": 2,
                                "name": "Mac"
                            }
                        ]
                    }

                    setOS_State_(data.data.map((platform_eleman)=>{
                        return {id:platform_eleman.id,name:platform_eleman.name,status:false}
                    }))
                });
        }
        ,[])


    const [os , setOs] = useState(OS_State_)

    const update_OS = (i) => {


        // if (i===6){
        //     ////////
        //     if (os[5].status){
        //         setOs(OS_State_)
        //     }else if (!os[5].status){
        //         setOs(
        //             OS_State_.map(veri => {
        //                 return {...veri, status: true};
        //             })
        //         )
        //     }
        //
        //     ////////
        // }else{
        //     const os_newstate = os.map(veri => {
        //
        //         if (veri.id === i) {
        //             if (veri.status){
        //                 return {...veri, status: false};
        //             }else if(!veri.status){
        //                 return {...veri, status: true};
        //             }
        //         }
        //
        //         return veri;
        //     });
        //     setOs(os_newstate);
        // }




    };
    console.log(os)

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//     const Browser_State_ = [
//         {id: 1, name: 'Chrome',status:false},
//         {id: 2, name: 'Edge',status: false},
//         {id: 3, name: 'Firefox',status: false},
//         {id: 4, name: 'Opera',status: false},
//         {id: 5, name: 'Safari',status: false},
//         {id: 6, name: 'All',status: false}
//     ];

    const [Browser_State_,setBrowser_State_] = useState([])

    useEffect(
        () => {

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://tikqa.herokuapp.com/tikqa/api/v1/operator/browser/w',
                headers: {
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJkaWxlayIsImV4cCI6MTY4NDQ5OTQyNywiaWF0IjoxNjg0NDg4NjI3fQ.giMwYRUw2qwTckJXXQryKzoaGixa7LWudzeNRGTyR2k',
                    'Cookie': 'JSESSIONID=1DDD3570993AFC79B8E1707D436DACC6'
                }
            };

            axios.request(config)
                .then((response) => {
                    setBrowser_State_(response.data.data.map((platform_eleman)=>{
                        return {id:platform_eleman.id,name:platform_eleman.name,status:false}
                    }))
                })
                .catch((error) => {
                    let data = {
                        "httpStatus": "OK",
                        "successful": true,
                        "errorCode": null,
                        "errorMessage": null,
                        "data": [
                            {
                                "createdAt": "2023-05-16T07:04:28.079776",
                                "createdBy": "denizkaragoz0",
                                "updatedAt": "2023-05-16T07:04:28.07978",
                                "updatedBy": "denizkaragoz0",
                                "id": 1,
                                "name": "Chrome"
                            }
                        ]
                    }

                    setBrowser_State_(data.data.map((platform_eleman)=>{
                        return {id:platform_eleman.id,name:platform_eleman.name,status:false}
                    }))
                });
        }
        ,[])



    const [browser , setBrowser] = useState(Browser_State_)


    const update_Browser = (i) => {



        if (i===6){
            ////////
            if (browser[5].status ===true){
                setBrowser(Browser_State_)
            }else if (browser[5].status ===false){
                setBrowser(
                    Browser_State_.map(veri => {
                        return {...veri, status: true};
                    })
                )
            }

            ////////
        }else{
            const browser_newstate = browser.map(veri => {



                if (veri.id === i) {
                    if (veri.status){
                        return {...veri, status: false};
                    }else if(!veri.status){
                        return {...veri, status: true};
                    }
                }

                return veri;
            });
            setBrowser(browser_newstate);
        }





    };



    console.log(browser)
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const initialState = [
        {id: 1, name: 'Pazartesi',status:true},
        {id: 2, name: 'Salı',status: false},
        {id: 3, name: 'Çarşamba',status: false},
        {id: 4, name: 'Perşembe',status: false},
        {id: 5, name: 'Cuma',status: false},
        {id: 6, name: 'Cumartesi',status: false},
        {id: 7, name: 'Pazar',status: false}
    ];

    const [gunler, setGunler] = useState(initialState);
    true_ = gunler.filter((item)=> !item.status)
    const updateState = (i) => {
        const newState = gunler.map(veri => {

            if (veri.id === i) {
               if (veri.status){
                   return {...veri, status: false};
               }else if(!veri.status){
                   return {...veri, status: true};
               }
            }

            return veri;
        });


        setGunler(newState);
    };
    ////////////////////////////////////////////////////////////////////////////////////////
    if (data[0] !='' && data[1] !=''){
        disp(error_update(false))
    }

    if (testdata!=null){
        testname= tests_[testdata].testname

    }else if(testdata==null){
        testname = data[0]
    }
    const step1 = useSelector ((state) => state.tests.step1)
    const page=()=>{
        disp(lastPage_Update('/step-3'))

        const eklenecek =  {
            testid:data.length + 1,
            testname: testname,
            status : true,
            lastdate: date ,
            creator : 'admin',
            gunler : gunler,
            testaraligi: testskl ,
            testtkr : tekrar ,
            platform : platform ,
            os: os ,
            browser : browser ,
            kabul : step1[1],
            etiket :step1[2]
        }

        disp(addTest(eklenecek))

    }



   useEffect(()=>{
           console.log(gunler)
      }
        ,[gunler]
    )

    const testGunleri = true_.map((e,i)=>

            true_.length  - 2   !== i ? (true_.length - 1 == i ? e.name  : e.name + ", "  ) : e.name + " ve "

    )

    const page1 = <section id="fluidContent">
        <div className="centerWrapper">
            <div className="stepWrapper">
                <ul>
                    <li> <Link to="/step-1" onClick={page} className="activeStep"> <span><img src="assets/img/edit.svg"/></span> Test bilgileri </Link> </li>
                    <li> <Link to="/step-2" onClick={page} className="activeStep"> <span><img src="assets/img/edit.svg"/></span> Test akışı oluştur </Link> </li>
                    <li> <Link to="/step-3" onClick={page} className="activeStep"> <span>3</span> Çalışma takvimi </Link> </li>
                </ul>
                <div className="howCreate">
                    <a href="#"> <img src="assets/img/settings2.svg" alt="" /> Test akışı nasıl oluşturulur</a>
                </div>
            </div>
            <div className="centerCard fullWidth formV">
                <div className="cardTitle"> <span>3</span> Çalışma takvimi</div>
                <div className="loginTestWrapper">
                    <a href="#" className="loginTest">{testname}</a>
                </div>
                <div className="dateCard">
                    <h1 className="textLeft"> <img src="assets/img/schedule.svg" alt="" /> Test Takvimi</h1>
                    <span className="textLeft">Kurguladığınız testin hangi zaman aralığını aşağıdaki seçimlerle belirleyebilirsiniz.</span>
                    <form action>
                        <div className="formBoxV3">
                            <div className="dateInputArea">
                                <div className="formInput">
                                    <label htmlFor="dateInput" >Test başlangıç tarihi</label>
                                    <input type="date" defaultValue={date}  data-date-format="DD MMMM YYYY" onChange={(e)=>setDate(e.target.value)} />
                                </div>
                                <div className="formInput">
                                    <label htmlFor="testname">Gün içindeki test sıklığı </label>
                                    <select name id onChange={(e)=>setTestskl(e.target.value)}>
                                        <option value="Her saat başı">Her saat başında</option>
                                        <option value="Günde 1">günde 1</option>
                                        <option value="ayda 2">ayda 2</option>
                                        <option value="yılda 3">yılda 3</option>
                                    </select>
                                </div>
                                <div className="formInput">
                                    <label htmlFor="testname">Testin tekrar edilme sayısı</label>
                                    <select name id onChange={(e)=>setTekrar(e.target.value)}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="checkTitle">Günler</div>
                            <div className="multipleCheck">

                                {gunler.map((e,i)=>


                                    <div className={e.status === true ? "checkBox checkedX" :"checkBox" }>
                                        <label className="containerX" style={{color:e.status ? 'white' : 'gray'}}> {e.name.toString()}
                                            <input type="checkbox"  value={e.id}  onChange={(e)=>updateState(i+1) } checked={e.status && "checked" } />
                                            <span className="checkmarkX" />
                                        </label>
                                    </div>
                                )}


                            </div>
                        </div>
                    </form>
                    <div className="calendarDesc">
                        <h2>Test takvim özeti:</h2>
                        <p> <strong>{tarih + "‘ den itibaren" }</strong>  <strong>{testskl} {tekrar} kere</strong> tekrar ederek <strong>
                            {testGunleri}</strong> günleri test gerçekleşecektir.</p>
                    </div>
                    <div className="formButton">
                        <button type="submit" onClick={()=>setPage(1)}><img src="assets/img/right.svg" alt="" /> Devam et</button>
                    </div>
                </div>
            </div>
        </div>
    </section>


    /////////////////////////////


    const page2 = <section id="fluidContent">
        <div className="centerWrapper">
            <div className="stepWrapper">
                <ul>
                    <li> <Link to="/step-1" onClick={page} className="activeStep"> <span><img src="assets/img/edit.svg"/></span> Test bilgileri </Link> </li>
                    <li> <Link to="/step-2" onClick={page} className="activeStep"> <span><img src="assets/img/edit.svg"/></span> Test akışı oluştur </Link> </li>
                    <li> <Link to="/step-3" onClick={page} className="activeStep"> <span>3</span> Çalışma takvimi </Link> </li>
                </ul>
                <div className="howCreate">
                    <a href="#"> <img src="assets/img/settings2.svg" alt="" /> Test akışı nasıl oluşturulur</a>
                </div>
            </div>
            <div className="centerCard fullWidth formV">
                <div className="cardTitle"> <span>3</span> Çalışma takvimi</div>
                <div className="loginTestWrapper">
                    <a href="#" className="loginTest">{testname}</a>
                </div>
                <div className="dateCard">
                    <h1 className="textLeft"> <img src="assets/img/settings2.svg" alt="" /> Browser &amp; İşletim sistemi &amp; Platform seçimi </h1>
                    <span className="textLeft">Kurguladığınız testin çalışacağı  Browser, İşletim sistemi ve platformlarıını</span>
                    <form action>
                        <div className="formBoxV3">
                            <div className="checkTitle mt37">Platform seçimi</div>
                            <div className="deviceSelect">
                                {/*<ul>*/}

                                {/*    <li className={platform[0].status === true && platform[1].status === true && platform[2].status === true   ? "checkedBox": ""}   > <label className="containerBox"> Tümü <input type="checkbox"  value="1" onClick={(e)=>update_Platform(4)}  checked={platform[0].status === true && platform[1].status === true && platform[2].status === true  ? true: false }/> <span className="checkmarkBox" />*/}
                                {/*    </label> </li >*/}
                                {/*    <li className={platform[0].status === true ? "checkedBox": ""} > <label className="containerBox">Desktop <img src="assets/img/pc.png" alt="" /><input type="checkbox"  value="2" onClick={(e)=>update_Platform(1)}  checked={platform[0].status === true ? true: false }/><span className="checkmarkBox"  />*/}
                                {/*    </label> </li>*/}
                                {/*    <li className={platform[1].status === true ? "checkedBox": ""} > <label className="containerBox">Tablet<img src="assets/img/tablet.png" alt="" /> <input type="checkbox" defaultValue="B" onClick={(e)=>update_Platform(2)}  checked={platform[1].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                {/*    </label> </li>*/}
                                {/*    <li className={platform[2].status === true ? "checkedBox": ""} > <label className="containerBox">Mobil<img src="assets/img/mobile.png" alt="" /> <input type="checkbox" defaultValue="C" onClick={(e)=>update_Platform(3)}  checked={platform[2].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                {/*    </label> </li>*/}

                                {/*</ul>*/}

                                <ul>
                                    <li className="">
                                        <label className="containerBox"> Tümü
                                        <input type="checkbox" value="D" onClick={(e)=>update_Platform("all")}/>
                                            <span className="checkmarkBox"></span>
                                        </label>
                                        </li>
                                    {
                                        platformState_.map((e,i)=>{
                                            return(
                                                <li className={e.status === true ? "checkedBox": ""} >
                                                    <label className="containerBox">{e.name.toString()}
                                                        <img src="assets/img/pc.png" alt="" />
                                                        <input type="checkbox" value={e.id} onClick={(e)=>update_Platform(e.target.value)} checked={e.status === true ? true: false }/>
                                                        <span className="checkmarkBox"></span>
                                                    </label>
                                                </li>
                                            )
                                        })
                                    }

                                </ul>
                            </div>
                            <div className="checkTitle mt37">İşletim sistemi seçimi</div>
                            <div className="deviceSelect">
                                <ul>
                                    <li className="">
                                        <label className="containerBox"> Tümü
                                            <input type="checkbox" value="D"/>
                                            <span className="checkmarkBox"></span>
                                        </label>
                                    </li>
                                    {/*<li className={os[0].status === true ? "checkedBox": ""}> <label className="containerBox">Mac OS <img src="assets/img/apple.png" alt="" /> <input type="checkbox" defaultValue="A" onClick={(e)=>update_OS(1)}  checked={os[0].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={os[1].status === true ? "checkedBox": ""}> <label className="containerBox">Linux<img src="assets/img/linux.png" alt="" /> <input type="checkbox" defaultValue="B" onClick={(e)=>update_OS(2)}  checked={os[1].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={os[2].status === true ? "checkedBox": ""} > <label className="containerBox">Windows 10<img src="assets/img/windows.png" alt="" /> <input type="checkbox" defaultValue="C" onClick={(e)=>update_OS(3)}  checked={os[2].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={os[3].status === true ? "checkedBox": ""}> <label className="containerBox">Windows 8<img src="assets/img/windows.png" alt="" /> <input type="checkbox" defaultValue="D" onClick={(e)=>update_OS(4)}  checked={os[3].status === true ? true: false }/><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={os[4].status === true ? "checkedBox": ""}> <label className="containerBox">Windows 7<img src="assets/img/windows.png" alt="" /> <input type="checkbox" defaultValue="E"  onClick={(e)=>update_OS(5)}  checked={os[4].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}

                                    {
                                        OS_State_.map((e,i)=>{
                                            return(
                                                <li className={e.status === true ? "checkedBox": ""} >
                                                    <label className="containerBox">{e.name.toString()}
                                                        <img src="assets/img/windows.png" alt="" />
                                                        <input type="checkbox" value={e.id} onClick={(e)=>update_OS(e.target.value)} checked={e.status === true ? true: false }/>
                                                        <span className="checkmarkBox"></span>
                                                    </label>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="checkTitle mt37">Browser seçimi</div>
                            <div className="deviceSelect">
                                <ul>
                                    <li className="">
                                        <label className="containerBox"> Tümü
                                            <input type="checkbox" value="D"/>
                                            <span className="checkmarkBox"></span>
                                        </label>
                                    </li>
                                    {/*<li className={browser[0].status === true ? "checkedBox": ""}> <label className="containerBox">Chrome <img src="assets/img/chrome.png" alt="" /> <input type="checkbox" defaultValue="A" onClick={(e)=>update_Browser(1)}  checked={browser[0].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={browser[1].status === true ? "checkedBox": ""}> <label className="containerBox">Edge<img src="assets/img/edge.png" alt="" /> <input type="checkbox" defaultValue="B" onClick={(e)=>update_Browser(2)}  checked={browser[1].status === true ? true: false } /><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={browser[2].status === true ? "checkedBox": ""}> <label className="containerBox">Firefox<img src="assets/img/firefox.png" alt="" /> <input type="checkbox" defaultValue="C" onClick={(e)=>update_Browser(3)}  checked={browser[2].status === true ? true: false }/><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={browser[3].status === true ? "checkedBox": ""}> <label className="containerBox">Opera<img src="assets/img/opera.png" alt="" /> <input type="checkbox" defaultValue="C" onClick={(e)=>update_Browser(4)}  checked={browser[3].status === true ? true: false }/><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}
                                    {/*<li className={browser[4].status === true ? "checkedBox": ""}> <label className="containerBox">Safari<img src="assets/img/safari.png" alt="" /> <input type="checkbox" defaultValue="C" onClick={(e)=>update_Browser(5)}  checked={browser[4].status === true ? true: false }/><span className="checkmarkBox" />*/}
                                    {/*</label> </li>*/}

                                    {
                                        Browser_State_.map((e,i)=>{
                                            return(
                                                <li className={e.status === true ? "checkedBox": ""} >
                                                    <label className="containerBox">{e.name.toString()}
                                                        <img src="assets/img/chrome.png" alt="" />
                                                        <input type="checkbox" value={e.id} onClick={(e)=>update_Browser(e.target.value)} checked={e.status === true ? true: false }/>
                                                        <span className="checkmarkBox"></span>
                                                    </label>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="toggleSwitch">
                                <input type="checkbox" id="screen" onChange={(e)=>setTestEkran(testEkran === true ? false : true)} checked={testEkran}/><label htmlFor="screen" /><span>Test ekran görüntüsü al</span>
                                <input type="checkbox" id="video" onChange={(e)=>setTestVideo(testVideo === true ? false : true)} checked={testVideo}/><label htmlFor="video" /><span>Test videosu kaydı al</span>
                            </div>
                        </div>
                    </form>
                    <div className="formButton">
                        <Link to="/success" onClick={page}><img src="assets/img/right.svg" alt="" /> Kurulumu tamamla</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>

    return(
        <>
            {page_state === 0 ? page1 : page2}
        </>

    )
}