import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {lastPage_Update, update_Test} from "../../redux/other/slice";
import {useEffect, useState} from "react";
import axios from "axios";

export default  function CreateTest() {
    const [createTempdata ,setCreateTempdata] =  useState(0)
    const disp = useDispatch()
    const [data, setData] = useState([])

    const get_data = ()=>{

        const config = {
            method: 'get',
            url: 'https://tikqa.herokuapp.com/tikqa/api/v1/test-case',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJkaWxlayIsImV4cCI6MTY4NDQ5OTQyNywiaWF0IjoxNjg0NDg4NjI3fQ.giMwYRUw2qwTckJXXQryKzoaGixa7LWudzeNRGTyR2k',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.parse(JSON.stringify(response.data.data)));
                setData(JSON.parse(JSON.stringify(response.data.data)))
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    useEffect(()=>{
        get_data()
    },[])
    const page=()=>{
        disp(lastPage_Update('/'))
    }

    // const update_Status =(i)=>{
    //     console.log("gelen değer : " + i)
    //     const newState = dize.map(veri => {
    //
    //         if (veri.testid === i) {
    //             if (veri.status === true){
    //                 return {...veri, status: false};
    //             }else if(veri.status === false){
    //                 return {...veri, status: true};
    //             }
    //         }
    //
    //         return veri;
    //     });
    //
    //     disp(update_Test(newState));
    // }
    //
    // console.log(data)


    const test_create_ = <section id="centerContent">
        <div className="centerWrapper">
            <div className="centerCard">
                <img src="assets/img/science.svg" alt="" />
                <h1>Kayıtlı bir test akışınız bulunmamaktadır. </h1>
                <p>Hemen test oluşturarak ürünlerinizi test etmeye başlayın. </p>
                <Link to="/step-1"  onClick={page}><img src="assets/img/add.svg" alt="" /> Test oluştur</Link>

            </div>
        </div>
    </section>
    const test_data = useSelector((state) => state.tests.data)
    return (
        <>

            {test_data.length == 0 || createTempdata == 1 ? test_create_ :

            <section id="fluidContent">
                <div className="centerWrapper">
                    <div className="centerCard fullWidth noBr noPad">
                        <div className="tableTop">
                            <div className="formInput modelSearch mb0">
                                <label className="pl52" htmlFor="action">Testler</label>
                                <input type="text" placeholder="Test ID, test adı ya da etiketinde ara" />
                            </div>
                            <div className="formButton">
                                <button type="submit" onClick={()=>setCreateTempdata(1)}><img src="assets/img/add.svg" alt="" /> Yeni test oluştur</button>
                            </div>
                        </div>
                        <div className="tableWrapper">
                            <div id="example_wrapper" className="dataTables_wrapper no-footer"><table id="example" className="display dataTable no-footer" style={{width: '100%'}}>
                                <thead>
                                <tr><th className="sorting_disabled" rowSpan={1} colSpan={1} style={{width: '130px'}}>Test  ID</th><th className="sorting_disabled" rowSpan={1} colSpan={1} style={{width: '188px'}}>Test Adı</th><th className="sorting_disabled" rowSpan={1} colSpan={1} style={{width: '100px'}}>Statü</th><th className="sorting_disabled" rowSpan={1} colSpan={1} style={{width: '292px'}}>Son Çalıştırma Tarihi</th><th className="sorting_disabled" rowSpan={1} colSpan={1} style={{width: '155px'}}>Oluşturan</th><th className="sorting_disabled" rowSpan={1} colSpan={1} style={{width: '101px'}}> </th><th className="sorting_disabled" rowSpan={1} colSpan={1} style={{width: '34px'}}> </th></tr>
                                </thead>
                                <tbody>

                                {data.map((e,i)=>

                                    <tr className="odd">
                                        <td>{e.id}</td>
                                        <td>{e.name}</td>
                                        <td> <div className="toggleSwitch noMar"> <input type="checkbox" id={"table-id" + i} checked={e.status ? true : false} onChange={
                                            ()=>{}
                                                // update_Status(e.testid)
                                        }/><label htmlFor={"table-id" + i} /> </div> </td>
                                        <td>{e.lastdate}</td>
                                        <td><strong>{e.creator}</strong></td>
                                        <td><a href="#">Rapor</a></td>
                                        <td> <button> <img src="assets/img/select.svg" alt="" /> </button> </td>
                                    </tr>

                                )}



                                </tbody>
                            </table></div>
                        </div>
                    </div>
                </div>
            </section>
            }
        </>

    )
}