import {configureStore} from "@reduxjs/toolkit";

import dataSlice from "./other/slice";
import Auth_Data from "./other/auth_data";

export const store = configureStore({

    reducer:{
        tests:dataSlice,
        auth_:Auth_Data
    },


})