import {createSlice} from "@reduxjs/toolkit";  //5


export const dataSlice = createSlice({
    name:'testler',
    initialState:{
        data:[
            {
            testid:0,
            testname: 'test',
            status : true,
            lastdate: 55 ,
            creator : 'Berkan',
            gunler : [],
            testaraligi: null ,
            testtkr : null ,
            platform : null ,
            os: null ,
            browser : null ,
            },

        ],
        test_crt:[

        ],
        last_page:'/',
        step1:['','', []],
        step2:'option',
        secilitest : null,
        succes1:false,
        succes2:false,
        succes3:false,
        error_:false,
        lastTestname:''

    },
    reducers:{
        addTest:(state, action)=>{
            state.data.push(action.payload)
        },
        update_Test:(state, action)=>{
            state.data = (action.payload)
        },
        lastPage_Update:(state, action)=>{
            state.last_page = (action.payload)
        },
        step1_update:(state, action)=>{
            state.step1 = (action.payload)
        },
        step2_update:(state, action)=>{
            state.step2 = (action.payload)
        },
        secilitest_update:(state, action)=>{
            state.secilitest = (action.payload)
        },
        succes1_update:(state, action)=>{
            state.succes1 = (action.payload)
        },
        error_update:(state, action)=>{
            state.error_ = (action.payload)
        }

    },


});
export const {addTest,lastPage_Update,step1_update,step2_update,secilitest_update,succes1_update,error_update,update_Test} = dataSlice.actions;
export default dataSlice.reducer;