import {useDispatch, useSelector} from "react-redux";
import {error_update, lastPage_Update, secilitest_update, step2_update} from "../../redux/other/slice";
import {Link} from "react-router-dom";
import {useState} from "react";

export function Step2(){
    const disp = useDispatch()
    const data = useSelector ((state) => state.tests.step1)
    const [testler,setTest] = useState([])
    const option_ = useSelector ((state) => state.tests.step2)
    const [secili,setSecili] = useState(9999)
    const [state_,setState] = useState(0)

    if (data[0] !='' && data[1] !=''){
    disp(error_update(false))
    }
    const [opt,setOpt]=useState(option_ != 'option' ? option_ :'1')


    const page=()=>{
        disp(lastPage_Update('/step-2'))
        disp(step2_update(opt))
        console.log(opt)

        if (opt == 2 ){
            setState(1)
        }
              
        if(secili!=9999){
            disp(secilitest_update(secili))

        }

    }

    const mainstep =         
    <section id="centerContent">
        <div className="centerWrapper">
            <div className="stepWrapper">
                <ul>
                    <li> <Link to="/step-1" onClick={page} className="activeStep"> <span><img src="assets/img/edit.svg"/> </span> Test bilgileri </Link> </li>
                    <li> <Link to="/step-2" onClick={page} className="activeStep"> <span>2</span> Test akışı oluştur </Link> </li>
                    <li> <Link to="/step-3" onClick={page}> <span>3</span> Çalışma takvimi </Link> </li>
                </ul>
                <div className="howCreate">
                    <a href="#"> <img src="assets/img/settings2.svg" alt="" /> Test akışı nasıl oluşturulur</a>
                </div>
            </div>
            <div className="centerCard formV">
                <div className="cardTitle"> <span>2</span> Test akışı oluştur</div>
                <div className="formBox p0">
                    <form action>
                        <div className="loginTestWrapper">
                            <a href="#" className="loginTest">{data[0]!= '' ?data[0] :'Yeni Test'}</a>
                            <p>Yeni test oluşturabilir ya da kütühaneden oluşturduğunuz önceki testlerle devam edebilirsiniz</p>
                        </div>
                        <div className="radioCheck">
                            <label className="container">Yeni test oluştur

                                <input type="radio" name="radio" onClick={()=>setOpt(1)}  checked={opt == '1' ? true : false}/>
                                <span className="checkmark" />
                            </label>
                            <label className="container">Kütüphaneden seç
                                <input type="radio" name="radio" onClick={()=>setOpt(2)} checked={opt == '2' ? true : false}/>
                                <span className="checkmark" />
                            </label>
                        </div>
                    </form>
                </div>
                <div className="formButton">

                        {opt == 1
                        ?
                        <Link  to="/step-2_2" onClick={page} className="formbutton" ><img src="assets/img/right.svg" alt="" /> Oluştur</Link>
                        :
                        <button  onClick={page} ><img src="assets/img/right.svg" alt="" /> Devam et</button>
                        }


                </div>
            </div>
        </div>
    </section>




    const library = <section id="fluidContent">
        <div className="centerWrapper">
            <div className="stepWrapper">
                <ul>
                    <li> <Link className="activeStep" to="/step-1" onClick={page}> <span> <img src="assets/img/edit.svg" alt="" /> </span> Test bilgileri </Link> </li>
                    <li> <Link className="activeStep" to="/step-2" onClick={page}> <span>2</span> Test akışı oluştur </Link> </li>
                    <li> <Link to="/step-3" onClick={page}> <span>3</span> Çalışma takvimi </Link> </li>
                </ul>
                <div className="howCreate">
                    <a href="#"> <img src="assets/img/settings2.svg" alt="" /> Test akışı nasıl oluşturulur</a>
                </div>
            </div>
            <div className="centerCard fullWidth formV">
                <div className="cardTitle"> <span>2</span> Test akışı oluştur</div>
                <div className="loginTestWrapper">
                    <a href="#" className="loginTest">{data[0]!= '' ?data[0] :'Yeni Test'}</a>
                </div>
                <div className="centerCard">
                    <h1 className="textLeft"> <img src="assets/img/library.png" alt="" /> Kütüphaneden test seçimi</h1>
                    <span>Kütphaneden hazırlamış olduğunuz testi akışına başlayabilirsiniz.</span>
                    <div className="formBox">
                        <form action>
                            <div className="formInput inputModelV2 inputBg mb20">
                                <label className="pl52" htmlFor="test">Test seçiminizi yapınız</label>
                                <select name id="test" onChange={(e)=>setSecili(e.target.value)}>

                                    {testler.map((e,i) =>
                                        <option key={i} value={i}>{e.testname}</option>
                                    )}
                                </select>
                            </div>
                        </form>
                    </div>
                    <div className="formButton">
                        <Link  to="/step-3" onClick={page} ><img src="assets/img/right.svg" alt="" /> Devam et</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>




    return(
        <>
            {state_ == 0 ? mainstep : library}
        </>

    )
}