import {Route, Routes} from "react-router-dom";
import Create_Test from "./pages/Create_Test";
import {useSelector} from "react-redux";
import Step_1 from "./pages/Step_1";
import Step_2 from "./pages/Step_2";
import Step_3 from "./pages/Step_3";
import Step_2_2 from "./pages/Step2_2";
import {Success_} from "./pages/Success_";
import Login_ from "./pages/Login_";
function App() {
    const data = useSelector((state)=>state.tests.data)
    console.log(data)
    // //                    <Route path="/login" element={<Login_/>}/>
  return (
      <div>
          <>
              <Routes>
                  <Route path="/" element={<Create_Test/>}/>
                  <Route path="/step-1" element={<Step_1/>}/>
                  <Route path="/step-2" element={<Step_2/>}/>
                  <Route path="/step-2_2" element={<Step_2_2/>}/>
                  <Route path="/step-3" element={<Step_3/>}/>
                  <Route path="/success" element={<Success_/>}/>
              </Routes>
          </>


      </div>
  );
}

export default App;
