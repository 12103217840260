import {lastPage_Update, secilitest_update, step2_update} from "../../redux/other/slice";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
export default  function Step2_2_Page(){
    const disp = useDispatch();
    const [data,setData] = useState([]);
    const auth = useSelector((state) =>state.auth_.token)
        console.log(auth)
        const get_data = ()=>{

        const config = {
            method: 'get',
            url: 'https://tikqa.herokuapp.com/tikqa/api/v1/event',
            headers: {
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJkaWxlayIsImV4cCI6MTY4NDQ5OTQyNywiaWF0IjoxNjg0NDg4NjI3fQ.giMwYRUw2qwTckJXXQryKzoaGixa7LWudzeNRGTyR2k',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.parse(JSON.stringify(response.data.data)));
                setData(JSON.parse(JSON.stringify(response.data.data)))
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    useEffect(()=>{
        get_data()
    },[])

   const element = data.map((e,i)=>


       <option value={e.id} selected>{e.eventName}</option>


)




    const page=()=>{
        disp(lastPage_Update('/step-2_2'))
    }
    return(

        <section id="fluidContent">
            <div className="centerWrapper">
                <div className="stepWrapper">
                    <ul>
                        <li> <a className="activeStep" href="#"> <span> <img src="assets/img/edit.svg" alt="" /> </span> Test bilgileri </a> </li>
                        <li> <a className="activeStep" href="#"> <span>2</span> Test akışı oluştur </a> </li>
                        <li> <a href="#"> <span>3</span> Çalışma takvimi </a> </li>
                    </ul>
                    <div className="howCreate">
                        <a href="#"> <img src="assets/img/settings2.svg" alt="" /> Test akışı nasıl oluşturulur</a>
                    </div>
                </div>
                <div className="centerCard fullWidth formV">
                    <div className="cardTitle"> <span>2</span> Test akışı oluştur</div>
                    <div className="loginTestWrapper">
                        <a href="#" className="loginTest">Amazon login testi</a>
                    </div>
                    <div className="formBoxV2">
                        <p className="testDesc">Testinizin birinci adımını oluşturduktan sonra yeni adımlar için adım ekle butonu ile  test kurulumunu yapabilirsiniz.</p>
                        <div className="formBg">
                            <div className="cardTitle positionCorner"> <span>1</span></div>
                            <form action>
                                <h3>Birinci adım</h3>
                                <div className="formInput inputModelV2 mb20">
                                    <label className="pl52" htmlFor="action">Aksiyon Seç</label>
                                    <select name id="action">
                                        {element}
                                      </select>
                                </div>
                                <div className="formInput inputModelV2 mb20">
                                    <label className="pl52" htmlFor="locator">Locator seçimi</label>
                                    <select name id="locator">
                                        <option value selected>XPath</option>
                                        <option value>AAAA</option>
                                        <option value>AAAA</option>
                                        <option value>AAAA</option>
                                        <option value>AAAA</option>
                                    </select>
                                </div>
                                <div className="formInput inputModelV2 mb20">
                                    <label htmlFor="locatorVal">Locator değeri</label>
                                    <input id="locatorVal" type="text" placeholder="UserName" />
                                    <span>İlgili alanın adını yazın</span>
                                </div>
                                <div className="formInput inputModelV2 mb20">
                                    <label htmlFor="locatorVal2">Locator değerinin önyüzdeki adı (isteğe bağlı)</label>
                                    <input id="locatorVal2" type="text" placeholder="Kullanıcı  adı" />
                                </div>
                                <div className="formInput inputModelV2 mb20">
                                    <label htmlFor="addInput">Input Ekle</label>
                                    <input id="addInput" type="text" placeholder />
                                    <button className="addfile">+ Dosya ekle</button>
                                    <span>Birden fazla input eklemek için dosya ekle butonunu kullanın</span>
                                </div>
                                <div className="formInput inputModelV2 mb20">
                                    <label htmlFor="note">Not Ekle</label>
                                    <input id="note" type="text" placeholder />
                                </div>
                                <div className="formButton">
                                    <Link className="cancelBtn" to="/step-2" onClick={page}>İptal</Link>
                                    <Link type="submit" to="/step-3" onClick={page}>Kaydet</Link>
                                </div>
                                <div className="addStep">
                                    <img src="assets/img/connect.png" alt="" />
                                    <button className="addStepBtn"> <img src="assets/img/add.svg" alt="" /> Adım Ekle</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}