import {createSlice} from "@reduxjs/toolkit";  //5


export const Auth_Data = createSlice({
    name:'testler',
    initialState:{
        token :"Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJkaWxlayIsImV4cCI6MTY4NDQ5OTQyNywiaWF0IjoxNjg0NDg4NjI3fQ.giMwYRUw2qwTckJXXQryKzoaGixa7LWudzeNRGTyR2k"


    },
    reducers:{
        update_token:(state, action)=>{
            state.token = (action.payload)
        },

    },


});
export const {update_token} = Auth_Data.actions;
export default Auth_Data.reducer;