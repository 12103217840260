import Header from "../components/temp/header";
import {Step3} from "../components/page/step3";
import {useDispatch, useSelector} from "react-redux";
import {error_update} from "../redux/other/slice";
import {Step1} from "../components/page/step1";

export default  function Step_2(){
    const disp = useDispatch()
    const data = useSelector ((state) => state.tests.step1)

    if(data[0] === '' || data[1] === ''){

        disp(error_update(true))
    }

    if (data[0] === '' || data[1] === ''){
        console.log(111)
    }
    return (
        <>
            <Header/>
            {data[0] !== '' && data[1] !== '' ? <Step3/> : <Step1/>}
        </>
    )
}